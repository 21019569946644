@keyframes shake {
    0% {
        transform: translateX(0);
    }
    10% {
        transform: translateX(-3px);
    }
    20% {
        transform: translateX(3px);
    }
    30% {
        transform: translateX(-3px);
    }
    40% {
        transform: translateX(3px);
    }
    50% {
        transform: translateX(-3px);
    }
    60% {
        transform: translateX(3px);
    }
    70% {
        transform: translateX(-3px);
    }
    80% {
        transform: translateX(3px);
    }
    90% {
        transform: translateX(-3px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes changeColor {
    from {
        background-color: red;
    }
}

.enter_invalid_code {
    animation: shake 0.2s, changeColor 0.6s;
}

.change_color {
    animation: changeColor 0.6s;
}
